[class^="ais-"] {
  box-sizing: border-box;
  font-size: .9rem;
}

a[class^="ais-"] {
  color: #6e7070;
  text-decoration: none;
}

[class^="ais-"][class$="--disabled"], [class^="ais-"][class$="--disabled"] * {
  cursor: not-allowed;
}

.ais-Breadcrumb, .ais-ClearRefinements, .ais-CurrentRefinements, .ais-ClearRefinements-button, .ais-GeoSearch, .ais-HierarchicalMenu, .ais-Hits, .ais-Results, .ais-HitsPerPage, .ais-ResultsPerPage, .ais-InfiniteHits, .ais-InfiniteResults, .ais-Menu, .ais-MenuSelect, .ais-NumericMenu, .ais-NumericSelector, .ais-Pagination, .ais-Panel, .ais-PoweredBy, .ais-RangeInput, .ais-RangeSlider, .ais-RatingMenu, .ais-RefinementList, .ais-SearchBox, .ais-SortBy, .ais-SortBy-select, .ais-HitsPerPage-select, .ais-Stats, .ais-ToggleRefinement {
  color: #000;
  font-size: .75rem;
}

.ais-Highlight-highlighted, .ais-Snippet-highlighted {
  background: #e6ebf7;
}

.ais-InfiniteHits-list {
  grid-gap: 2.5rem;
  grid-template-columns: 1fr;
  display: grid;
}

@media (min-width: 680px) {
  .ais-InfiniteHits-list {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 1200px) {
  .ais-InfiniteHits-list {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.ais-RefinementList-item, .ais-Menu-item, .ais-NumericMenu-item, .ais-HierarchicalMenu-item, .ais-RatingMenu-item {
  -webkit-user-select: none;
  user-select: none;
}

.ais-RefinementList-item, .ais-RefinementList-item *, .ais-NumericMenu-item, .ais-NumericMenu-item *, .ais-RatingMenu-item {
  cursor: pointer;
}

.ais-HierarchicalMenu-link, .ais-RatingMenu-item, .ais-NumericMenu-item, .ais-RefinementList-item {
  padding-bottom: 1rem;
}

.ais-Breadcrumb-item--selected, .ais-HierarchicalMenu-item--selected, .ais-NumericMenu-item--selected, .ais-Menu-item--selected {
  font-weight: bold;
}

.ais-RatingMenu-starIcon--full {
  fill: #6634d1;
}

.ais-RatingMenu-starIcon--empty {
  fill: #00000014;
}

.ais-Panel--collapsible {
  position: relative;
}

.ais-Panel--collapsed .ais-Panel-body, .ais-Panel--collapsed .ais-Panel-footer {
  display: none;
}

.ais-Panel-collapseButton {
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  position: absolute;
  right: 0;
}

.ais-Panel-header {
  text-transform: uppercase;
  padding-bottom: 1rem;
  font-family: Montserrat;
  font-size: .875rem;
  font-weight: bold;
}

.ais-SearchBox-form {
  position: relative;
}

.ais-SearchBox-input {
  appearance: none;
  color: 0;
  background: none;
  border: none;
  border-bottom: 1px solid #e6ebf7;
  outline: none;
  padding: .5rem .5rem .5rem 56px;
  font-family: inherit;
  font-size: 1.4375rem;
}

.ais-SearchBox-input::placeholder {
  color: #00000080;
  opacity: 1;
}

.ais-SearchBox-input:-ms-input-placeholder {
  color: #00000080;
}

.ais-SearchBox-input::-moz-placeholder {
  color: #00000080;
}

.ais-SearchBox-reset, .ais-SearchBox-loadingIndicator, .ais-SearchBox-submit {
  appearance: none;
  height: 100%;
  width: 1rem;
  align-items: center;
  display: flex;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.ais-SearchBox-submit[hidden], .ais-SearchBox-reset[hidden], .ais-SearchBox-loadingIndicator[hidden] {
  display: none;
}

.ais-SearchBox-submit {
  left: 0;
}

.ais-SearchBox-submit svg {
  color: #fff;
  height: 19px;
  width: 19px;
}

.ais-SearchBox-reset, .ais-SearchBox-loadingIndicator {
  right: 0;
}

.ais-SearchBox-resetIcon {
  height: 10px;
  width: 10px;
}

.ais-RefinementList .ais-SearchBox-input {
  color: #21243dcc;
  width: 100%;
  border-radius: 3px;
  padding: .5rem 1.5rem;
  font-size: .875rem;
}

.ais-RefinementList .ais-SearchBox-form {
  margin-bottom: 1rem;
}

.ais-RefinementList .ais-SearchBox-submit svg {
  color: #e6ebf7;
  height: 17px;
  width: 17px;
}

.ais-HierarchicalMenu-link, .ais-RatingMenu-link, .ais-RefinementList-label {
  align-items: center;
  display: flex;
}

.ais-RefinementList-checkbox, .ais-NumericMenu-radio {
  appearance: none;
  height: 1.25rem;
  min-width: 1.25rem;
  background: none;
  border: 2px solid #e6ebf7;
  margin: 0 .5rem 0 0;
  transition: border-color .1s;
  position: relative;
}

.ais-RefinementList-checkbox {
  border-radius: 4px;
}

.ais-RefinementList-item--selected {
  font-weight: bold;
}

.ais-RefinementList-item--selected .ais-RefinementList-checkbox {
  background-color: #6634d1;
  border-color: #6634d1;
}

.ais-RefinementList-item--selected .ais-RefinementList-checkbox:after, .ais-NumericMenu-item--selected .ais-NumericMenu-radio:after {
  content: "";
  height: 4px;
  width: 4px;
  background-color: #fff;
  border-radius: 4px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-2px)translateY(-2px);
}

.ais-RefinementList-item:not(.ais-RefinementList-item--selected) .ais-RefinementList-checkbox:hover {
  border-color: #6634d1;
}

.ais-NumericMenu-label {
  align-items: center;
  display: flex;
}

.ais-NumericMenu-radio {
  border-radius: 50%;
}

.ais-NumericMenu-item--selected .ais-NumericMenu-radio {
  background-color: #6634d1;
  border-color: #6634d1;
}

.ais-NumericMenu-item:not(.ais-NumericMenu-item--selected) .ais-NumericMenu-radio:hover {
  border-color: #6634d1;
}

.ais-HierarchicalMenu-count, .ais-Menu-count, .ais-RefinementList-count, .ais-ToggleRefinement-count, .ais-RatingMenu-count {
  color: #6634d1;
  color: 6634d1;
  min-height: 21px;
  background-color: #e6ebf7;
  border-radius: 4px;
  align-items: center;
  margin-left: .625rem;
  padding: 3px 8px;
  font-size: .6875rem;
  display: flex;
}

.ais-SortBy, .ais-HitsPerPage {
  position: relative;
}

.ais-SortBy:after, .ais-HitsPerPage:after {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='9' viewBox='0 0 16 9'%3E%3Cpath fill='none' fill-rule='evenodd' stroke='%236634D1' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M1 1l7 7 7-7'/%3E%3C/svg%3E%0A");
  display: inline-block;
}

.ais-SortBy-select, .ais-HitsPerPage-select {
  appearance: none;
  text-transform: uppercase;
  background: none;
  border: none;
  font-family: inherit;
  font-size: .875rem;
  font-weight: bold;
}

.ais-Stats {
  color: #6e7070;
  font-size: .875rem;
}

/*# sourceMappingURL=index.6f6b2d3d.css.map */
